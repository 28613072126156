<template>
  <Layout el="hm fixed">
    <Header>
      <List el="column-between xsm" class="v-center">
        <List el="column md" class="v-center">
          <Icon name="backspace" @click="close" />
          <!-- <img src="../assets/logo/alphaleaf_logo_brandmark_color.svg" id="mobileLogo" alt="logo" el="logo"> -->
          <Text el="h4">{{ formatFilename }}</Text>
        </List>
        <List el="column xsm" class="v-center">
          <!-- <Icon name="file_download" @click="logActivity" /> -->
        </List>
      </List>

      <!-- <List el="xsm" style="padding:32px;">
        <List el="column-between" class="v-center">
          <Text el="h3" v-if="file != null">{{ file.label }}</Text>
          <Text el="h3" v-else>File does not exist</Text>
          <Icon name="close" @click="close" />
        </List>
      </List> -->
    </Header>
    <main el="main">
      <div v-if="url != null">
        <img :src="url" alt="image" loading="lazy" v-if="fileType == 'image'" />
        <video
          :src="url"
          loading="lazy"
          v-else-if="fileType == 'video'"
          controls
          @contextmenu="preventDownload($event)"
          controlsList="nodownload"
        />
        <iframe
          :src="url"
          frameborder="0"
          v-else-if="fileType == 'txt'"
          class="txt"
        ></iframe>
        <Markdown :url="url" v-else-if="fileType == 'md'" />
        <iframe :src="url" frameborder="0" v-else></iframe>
        <!-- <Text el="p">Text</Text> -->
      </div>
    </main>
  </Layout>
</template>
<script>
let includes = require("lodash/includes");
import router from "../router";
// import { db } from "@/firebase";
import { db, auth } from "@/firebase";
import { addDoc, collection } from "firebase/firestore";
import Icon from "@/components/btn/Icon.vue";
import Markdown from "@/components/Markdown.vue";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  components: {
    Icon,
    Markdown
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      main: this.$store.state.main.layout.main,
      section: this.$store.state.main.layout.section,
      file: null,
      url: null,
      activity: null,
    };
  },
  watch: {
    "$route.params.path": function () {
      this.getFile();
    },
  },
  methods: {
    preventDownload(e) {
      //do stuff
      e.preventDefault();
    },
    async getFile() {
      // let routeParams = this.$route.params.path;
      // if (routeParams != null) {
      //   const docRef = doc(db, "recordings", this.$route.params.path);
      //   const docSnap = await getDoc(docRef);
      //   this.recording = docSnap.data();
      // }
      let x = this;
      if (
        this.$route.params.path != null &&
        this.$route.params.filename != null
      ) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          this.$route.params.collection +
            "/" +
            this.$route.params.path +
            "/" +
            this.$route.params.filename
        );

        getDownloadURL(storageRef).then((downloadURL) => {
          // console.log("File available at", downloadURL);
          x.url = downloadURL;
        });
      }
    },
    // activity tracking
    async logActivity(){
      let data = {
        label: this.formatFilename,
        filename: this.$route.params.filename,
        collection: this.$route.params.collection,
        docID: this.$route.params.path,
        url: this.url,
        uid: auth.currentUser.uid,
        userDisplayName: this.user.displayName,
        userGroups: this.user.groups,
        action:'view',
        date: new Date(),
      };
      await addDoc(collection(db, "activity"), data);
      console.log('activity tracked');
    },
    close() {
      // this.section.collapse = true;
      router.go(-1);
    },
  },
  mounted() {
    let x = this;
    this.getFile();
    x.activity = setTimeout(x.logActivity, 10000);
  },
  beforeUnmount(){
    clearTimeout(this.activity);
  },
  computed: {
    formatFilename() {
      let filename = this.$route.params.filename;
      // console.log(filename);
      if (filename != null) {
        let nameArr = filename.split(".");
        nameArr.pop();
        return nameArr.join("");
      } else {
        return "";
      }
    },
    fileType() {
      const file = this.$route.params.filename;
      let fileArr = file.split(".");
      const fileExt = fileArr.pop();
      if (includes(["jpg", "png", "webp"], fileExt)) {
        return "image";
      } else if (includes(["mp4"], fileExt)) {
        return "video";
      } else if (includes(["txt"], fileExt)) {
        return "txt";
      } else if (includes(["md"], fileExt)) {
        return "md";
      } else {
        return "iframe";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
[el^=layout-hm] {
    --layout-columns: [header main] 1fr;
    --layout-rows: [header] minmax(50px, max-content) [main] 1fr;
}
[el="header"] {
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
}
[el="main"] {
  --bg-color: rgba(0, 0, 0, 0.85);
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
}
.v-center {
  display: grid;
  align-items: center;
}
iframe,
video {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0;
  left: 0;
  top: 0;
}
main img {
  width: calc(100% - 40px);
  height: 100%;
  max-width: 1200px;
  max-height: 100%;
  // object-fit: contain;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba($color: #000000, $alpha: 0.25);
  margin: 20px;
  box-sizing: border-box;
}
#mobileLogo {
  height: 25px;
}
.logo-symbol {
  fill: var(--color-ocean);
}
.txt {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  max-width: 8.5in;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  margin: 20px 0;
  box-sizing: border-box;
  left: 50%;
  transform: translateX(-50%);
}
</style>
